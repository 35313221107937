<template>
  <v-container>
    <v-row dense>
      <v-toolbar dense flat class="login" style="background: transparent">
        <div
          class="Heading-Dark-H4-18-Center"
          style="width: 100%; position: absolute"
        >
          {{ $t("login.createPassword") }}
        </div>
      </v-toolbar>
    </v-row>
    <v-form>
      <v-row dense class="mt-16 mx-3">
        <v-col cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="pw1"
            :error-messages="pw1Errors"
            solo
            :placeholder="$t('profile.password')"
            required
            maxlength="50"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="show = !show"
            @input="$v.pw1.$touch()"
            @blur="getPw1Errors()"
            height="44"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            dense
            v-model="pw2"
            :error-messages="pw2Errors"
            solo
            :placeholder="$t('profile.confirmPassword')"
            required
            maxlength="50"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="show = !show"
            @input="$v.pw2.$touch()"
            @blur="getPw2Errors()"
            height="44"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row dense class="mx-3">
      <v-col>
        <v-btn
          @click="createPassword"
          width="100%"
          height="48"
          depressed
          color="primary"
          class="gradient mt-5 Heading-White-H4-18-Center"
          >{{ $t("login.create") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  validations: {
    pw1: { required, minLength: minLength(8) },
    pw2: { required, sameAsPassword: sameAs("pw1") },
  },
  data: () => ({
    pw1: "",
    pw2: "",
    show: false,
    pw1Errors: [],
    pw2Errors: [],
  }),
  methods: {
    // TODO Update to new password
    createPassword() {
      this.$v.touch();

      this.getPw1Errors();
      this.getPw2Errors();

      if (this.$v.$invalid) {
        return;
      }
      this.$router.safePush({ name: "login" });
    },

    getPw1Errors() {
      const errors = [];

      if (!this.$v.pw1.$dirty) {
        this.pw1Errors = errors;
        return false;
      }

      !this.$v.pw1.minLength && errors.push(this.$t("profile.atLeastChars"));
      !this.$v.pw1.required && errors.push(this.$t("profile.requirePassword"));

      this.pw1Errors = errors;
      return errors.length > 0;
    },

    getPw2Errors() {
      const errors = [];

      if (!this.$v.pw2.$dirty) {
        this.pw2Errors = errors;
        return false;
      }

      !this.$v.pw2.sameAsPassword &&
        errors.push(this.$t("profile.notIdentical"));
      !this.$v.pw2.required && errors.push(this.$t("profile.confirmPassword"));

      this.pw2Errors = errors;
      return errors.length > 0;
    },
  },
};
</script>
